var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('cit-data-table',{attrs:{"appends":[
      'split_customer_count'
    ],"headers":_vm.headers,"includes":[
      'customersCount'
    ],"resource-path":"users"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('create-form')]},proxy:true},{key:"item.split_customer_count",fn:function(ref){
    var item = ref.item;
return [_c('table',{staticClass:"w-full"},[_c('tr',{staticClass:"font-weight-bold"},[_c('td',[_vm._v("Total")]),_c('td',{attrs:{"align":"right","width":"50"}},[_vm._v(_vm._s(item.customers_count))])]),_vm._l((item.split_customer_count),function(count,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(count.name))]),_c('td',{attrs:{"align":"right","width":"50"}},[_vm._v(_vm._s(count.count))])])})],2)]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('view-button',{attrs:{"to":{name:'view-user', params:{id:item.id}},"target":"_self"}}),_c('action-delete',{attrs:{"action-url":("users/" + (item.id))}}),_c('reset-password-button',{attrs:{"user":item}}),_c('edit-form',{attrs:{"item":item}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }