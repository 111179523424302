<template>
  <action-edit
    :action-url="`users/${item.id}`"
    :item="item"
    button-label="Editeaza Agentul"
    @cancel="clearFile" @saved="clearFile"
  >

    <template v-slot:fields="{form, errors}">

      <div class="mb-4">
        <v-text-field v-model="form.first_name" dense hide-details label="Nume" outlined/>
        <error-messages :errors="errors.first_name"/>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.last_name" dense hide-details label="Prenume" outlined/>
        <error-messages :errors="errors.last_name"/>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.phone" dense hide-details label="Nr. Telefon" outlined/>
        <error-messages :errors="errors.phone"/>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.email" dense hide-details label="Email" outlined/>
        <error-messages :errors="errors.email"/>
      </div>

      <div class="mb-4">
        <v-select
          v-model="form.role_id"
          :items="roles.data"
          dense
          hide-details
          item-text="pretty_name"
          item-value="id"
          label="Rol"
          outlined
        ></v-select>
        <error-messages :errors="errors.role_id"/>
      </div>

      <div class="mb-4">
        <v-select
          v-model="form.public_display"
          :items="yesNoValues"
          dense
          hide-details
          item-text="text"
          item-value="value"
          label="Afisat in website"
          outlined
        ></v-select>
        <error-messages :errors="errors.public_display"/>
      </div>

      <div class="mb-4">

        <div v-if="item.avatar">
          <v-img
            :src="item.avatar"
            class="mb-4"
            contain
            max-height="150"
            max-width="500"
          />
        </div>

        <base64-file-uploader
          ref="avatar"
          v-model="form.avatar_file"
          label="Avatar"
        />
        <error-messages :errors="errors.avatar"/>
      </div>


      <div class="mb-4">
        <div class="text-sm">
          <strong class="text-danger">*</strong> La adaugarea unui utilizator se va trimite un email automat catre
          emailul setat mai sus. Acest email va contine linkul de setare a noi parole.
        </div>
      </div>


    </template>
  </action-edit>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ActionEdit from '@/components/layout/ActionButtons/ActionEdit'
import Base64FileUploader from '@/components/general-form/Base64FileUploader'

export default {
  components: {
    Base64FileUploader,
    ActionEdit,
    ErrorMessages
  },
  props: {
    item: {
      required: true
    }
  },

  data () {
    return {
      yesNoValues: [
        {
          text: 'Da',
          value: true
        },
        {
          text: 'Nu',
          value: false
        }
      ]
    }
  },
  methods: {
    clearFile () {
      this.$refs.avatar.clearFile()
    }
  },

  computed: {
    roles () {
      return this.$store.getters['users/getUserRoles']
    }
  },

  created () {
    this.$store.dispatch('users/loadRoles')
  }
}

</script>
